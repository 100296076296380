/* eslint-disable tailwindcss/no-custom-classname */
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType';
import { FormattedMessage } from 'react-intl';
import { SwiperSlide } from 'swiper/react';
import Button from '../../../atoms/button/Button';
import FrontasticImage from '../../../atoms/frontasticImage';
import Slider from '../../../atoms/slider';

const SLIDER_COLORS_SETTINGS = {
  slidesPerView: 4,
  slidesPerGroup: 4,
  spaceBetween: 6,
};

const ProductColor = ({ product, colors, handleMouseOverVariant }) => {
  const isDesktop = useDeviceType() === 'desktop';

  if (!colors.length || !isDesktop) {
    return null;
  }

  return (
    <div className="product-teaser--foot">
      <div className={'product-teaser--slider-wrapper'}>
        <div className={'product-teaser--slider-variant is--algolia'}>
          <Slider {...SLIDER_COLORS_SETTINGS}>
            {colors.map((color) => (
              <SwiperSlide key={color.product_id}>
                <Button
                  type={'button'}
                  className="btn w-full p-1"
                  ariaLabel={color.name}
                  onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      handleMouseOverVariant(color);
                  }}
                >
                  <FrontasticImage title={color.name} alt={color.name} width={48} height={48} src={color.image} />
                </Button>
              </SwiperSlide>
            ))}
          </Slider>
        </div>
      </div>
      <p className="product-teaser--count">
        {product.colors?.length}{' '}
        <FormattedMessage id={product.colors?.length === 1 ? 'productTeaser.color' : 'productTeaser.colors'} />
      </p>
    </div>
  );
};

export default ProductColor;

import React from 'react'

type TagProps = {
    label: string
    backgroundColor?: string
}

const Tag: React.FC<TagProps> = ({ label, backgroundColor }: TagProps) => {
    if (!label) {
        return null
    }

    const styleBackgroundOfTagElement = backgroundColor ? { backgroundColor } : null

    return (
        <div className={'tag rounded-sm text-center text-white uppercase font-Sport2000 font-bold leading-4'} style={styleBackgroundOfTagElement}>{label}</div>
    )
}

export default Tag

import React from 'react'
import uniq from 'lodash.uniq'
import classnames from 'classnames'
import Tag from '.'

type InternalCategorySport2000Tag = 'exklusivmodell' | 'neu' | 'sale'
type BackgroundColorByTagType = { [K in InternalCategorySport2000Tag]?: string }
type ProductTagProps = {
    tags: string[]
    className?: string
}

const DEFAULT_BACKGROUND_COLOR = '#00aec7'
const TAGS_IS_VALID_TO_DISPLAY: InternalCategorySport2000Tag[] = ['exklusivmodell', 'sale']
const BACKGROUND_COLOR_BY_TAG: BackgroundColorByTagType = {
    exklusivmodell: '#00aec7',
    neu: '#000000',
    sale: '#EC0000',
}

const ProductTags: React.FC<ProductTagProps> = ({ tags, className }: ProductTagProps) => {
    if (!tags || tags.length <= 0) {
        return null
    }

    const isIncludedValidTags = (tag: string): boolean => TAGS_IS_VALID_TO_DISPLAY.findIndex((tagValid) => String(tagValid).toLowerCase() === tag.toLowerCase()) >= 0
    const tagsIsValidToDisplay: string[] = uniq(tags.filter(isIncludedValidTags))

    if (!tagsIsValidToDisplay || tagsIsValidToDisplay.length <= 0) {
        return null
    }

    return (
        <div className={classnames('flex flex-wrap gap-1', className)}>
            {tagsIsValidToDisplay.map((tag: string, index: number) => <Tag key={`tag_${tag}_${index}`} label={tag} backgroundColor={BACKGROUND_COLOR_BY_TAG[tag.toLowerCase()] || DEFAULT_BACKGROUND_COLOR} />)}
        </div>
    )
}

export default ProductTags

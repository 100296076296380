import fetch from '@frontastic/bridge/js/fetch'

class ProductColorService {
    async getColors(codes) {
        try {
            const res = await fetch('/api/product-color/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    codes,
                }),
            })
            return await res.json()
        } catch (e) {
            throw e
        }
    }

    bindColors(items, colorRes) {
        const tmpItems = items.concat()
        tmpItems.forEach((item) => {
            const { attributes } = item.variants[0]
            if (attributes.att_prod_root_model_code) {
                const productColors = colorRes[attributes.att_prod_root_model_code] ?? []
                productColors.forEach((productColor) => {
                    productColor._url = productColor.projectSpecificData.url
                    item.colors.push({
                        product: productColor,
                        isActive: (productColor.productId === item.productId),
                    })
                })
            }
        })
        return tmpItems
    }
}

export default new ProductColorService()
